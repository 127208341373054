///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules. Don't use a mixin where you could use
/// @extend instead.
///

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Style input placeholders
///
@mixin input-styled-check($icon, $suffix: 'checked', $padding: 5px) {
  // hide the actual element
  position: absolute;
  left: -9999px;
  ~ label,
  ~ .label {
    @include icon($icon);
    &::before {
      margin-right: $padding;
    }
  }
  &:checked {
    ~ label,
    ~ .label {
      @include icon($icon + '--' + $suffix);
    }
  }
}

///
/// Circles
///
/// Usage:
/// @include circle(200px, #999);
///
@mixin circle($default-circle-width: 41px, $color: #ccc) {
  width: $default-circle-width;
  height: $default-circle-width;
  background: $color;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

///
/// Plain Circle (for shape only)
///
/// Usage:
/// @include circle-plain(200px);
///
@mixin circle-plain($default-circle-width: 41px) {
  width: $default-circle-width;
  height: $default-circle-width;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

///
/// Triangles - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
/// @include bordered-triangle(size, color, direction, border-color, [border-width], [cut])
///
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
///
/// border-color: The color of the border around the triangle
/// border-width: The width of the border around the triangle
/// cut: Removes the border along the hypotenuse
///
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

@mixin bordered-triangle($size, $color, $direction, $border-color, $border-width: 1px, $cut: true) {
  $back-size: $size + ($border-width * 2);
  position: relative;
  min-width: $size;
  min-height: $size;
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    @include triangle($size, $color, $direction);
  }
  &:before {
    @include triangle($back-size, $border-color, $direction);
    $mid-border-width: ($border-width / 2);
    $border-width-and-mid: $border-width + $mid-border-width;
    @if ($cut == true) {
      $mid-border-width: 0;
      $border-width-and-mid: $border-width * 2;
    }
    @if ($direction == up) or ($direction == down) {
      margin-left: -$border-width;
      @if ($direction == down) {
        margin-top: -$mid-border-width;
      } @else {
        margin-top: -$border-width-and-mid;
      }
    } @else if ($direction == right) or ($direction == left) {
      margin-top: -$border-width;
      @if ($direction == right) {
        margin-left: -$mid-border-width;
      } @else {
        margin-left: -$border-width-and-mid;
      }
    }
  }
}

///
/// Vertical centerer
/// Source: http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
/// Note: This doesn't work in old browsers (ie. IE), you have to use javascript
/// for them if it's important.
///
@mixin vertical-center {
  @include transform(translateY(-50%));
  position: relative;
  top: 50%;
}

%vertical-center {
  @include vertical-center;
}

@mixin destroy-vertical-center {
  @include transform(none);
  position: static;
  top: auto;
}

%destroy-vertical-center {
  @include destroy-vertical-center;
}

///
/// Vertical & Horizontal centerer
///
@mixin vertical-horizontal-center {
  @include transform(translate(-50%, -50%));
  position: relative;
  top: 50%;
  left: 50%;
}

%vertical-horizontal-center {
  @include vertical-horizontal-center;
}

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

// FOR NAV COLUMNS

///
/// These are the padding-top values (in ems) each font needs to offset the
/// descender height for vertically-centering text.
///
$text-offsets: (
  text: 0.26667,
  heading: 0.3,
  subheading: 0.1,
  cta: 0.4,
  cta--lowercase: 0.18333
);
///
/// Use this to vertically center text by adding padding-top to the element
/// so that the amount of space between the cap line and top bearing line is
/// equal to the amount of space between the base line and the bottom bearing
/// line:
///
/// @include font-padding(text);
///
/// Use this if you want the element to be a certain height (in pixels),
/// adjusted for that top padding (assuming the font is displayed at 14px and
/// you want height of the element to be 30px):
///
/// @include font-padding(subheading, 14px, 30px)
///
@mixin font-padding($font, $font-size-px: false, $height-px: false) {
  @if $font-size-px {
    // If $font-size-px is convert and round the text-offset to pixels:
    padding-top: round(map-get($text-offsets, $font) * $font-size-px);
    // If $height-px was also passed, set the line-height to that with the
    // padding-top we just added subtracted.
    @if $height-px {
      $offset-px: map-get($text-offsets, $font) * $font-size-px;
      line-height: round($height-px - $offset-px);
    }
  } @else {
    padding-top: #{map-get($text-offsets, $font)}em;
  }
}

//
// Usage:
// @include link-stub() for red underline stub
// @include link-stub(black) for black underline stub
// Up to you where you want to use it e.g. &, &:hover, or &.active, etc.
// See: _helpers.scss if you want to use classes directly on your element
//
@mixin link-stub($color: red) {
  position: relative;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    border: 0;
  }
  &:before {
    display: block;
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 22px;
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 100% 50%;
    image-rendering: -webkit-optimize-contrast;
    @if $color == red {
      background-image: url('/media/images/global/link_medium_red_underline.png');
    } @else {
      background-image: url('/media/images/global/link_medium_black_underline.png');
    }
  }
}

@mixin link-animated-underline() {
  position: relative;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  &:before {
    display: none;
  }
  &:hover {
    text-decoration: none;
    border: 0;
    &:before {
      display: block;
      content: '';
      position: absolute;
      text-decoration: none;
      border: 0;
      bottom: -7px;
      #{$ldirection}: -5px;
      width: 100%;
      height: 11px;
      background-repeat: no-repeat;
      background-image: url('/media/export/cms/content_blocks/animated_underline.gif');
    }
  }
}

@mixin bordered-link($undo: false) {
  @include font--subheading-alt;
  display: inline-block;
  border-bottom: 1px solid $color-black;
  &:hover {
    text-decoration: none;
  }
  @if $undo {
    border-bottom: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

@mixin button() {
  @include font-smoothing;
  @include appearance(none);
  background: $color-black;
  border: 2px solid $color-black;
  color: $color-white;
  display: inline-block;
  font-family: $font--heading-main;
  font-size: 18px;
  font-weight: 100;
  padding: 6px 10px 6px 10px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  &:hover {
    background: $color-white;
    border-color: $color-black;
    color: $color-black;
    text-decoration: none;
  }
}

// light version so we can use in styles only
@mixin button--light() {
  background: $color-white;
  color: $color-black;
  border: 2px solid $color-white;
  &:hover {
    border-color: $color-black;
  }
}

@mixin fixed-bundle-cta-button() {
  padding: 20px 0;
  position: fixed;
  width: 100%;
  z-index: 3;
  bottom: 0;
  #{$ldirection}: 0;
  &--disabled {
    background-color: #{$color-light-gray};
  }
}

// constrain content area to 1280
@mixin content-contain {
  max-width: 1280px;
  margin: 0 auto;
}
// constrain content area to 1184
@mixin content-contain--narrow {
  max-width: 1184px;
  margin: 0 auto;
}

@mixin link {
  color: $color-link;
  text-decoration: underline;
  text-transform: uppercase;
  outline: 0;
  &:hover {
    color: $color-link-hover;
    outline: 0;
  }
  &:focus,
  &:active {
    outline: none;
  }
}

@mixin fluid-image {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

@mixin menu__left-caret {
  @include icon('caret--left', before);
  &:before {
    @include transform(translateY(-50%));
    position: absolute;
    left: -15px;
    top: 50%;
    font-size: 12px;
    font-weight: bold;
    @include breakpoint($landscape-up) {
      content: '';
    }
  }
}

@mixin menu__caret {
  @include icon('caret--right', after);
  &:after {
    @include transform(translateY(-50%));
    position: absolute;
    right: 25px;
    top: 50%;
    font-size: 12px;
    font-weight: bold;
    @include breakpoint($landscape-up) {
      content: '';
    }
  }
}

@mixin default-ul {
  list-style: disc;
  margin: 1em 0 2em 0;
  padding: 0;
  li {
    margin: 0 0 0.7em 3em;
  }
}

@mixin default-ol {
  list-style: decimal;
  margin: 1em 0 2em 0;
  padding: 0;
  li {
    margin: 0 0 0.7em 3em;
  }
}

/// main text
/// conversion from styleguide
/// 12pt /20pt - 40pt tracking
/// 16px / 1.6em / .04em

@mixin font--text {
  font-family: $font--text;
  font-weight: normal;
}

// helper mixin to display as plain copy
@mixin basic-text-reset {
  font-family: $font--text;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  text-transform: none;
}

///
/// Headers
///

@mixin font--heading {
  font-family: $font--heading-main;
  text-transform: uppercase;
  font-weight: 100;
}

@mixin font--subheading {
  font-family: $font--subheading;
  font-weight: normal;
  text-transform: uppercase;
}

@mixin font--subheading-alt {
  font-family: $font--subheading-alt;
  font-weight: normal;
  text-transform: uppercase;
}

// large display heading for heroes
@mixin font--heading-display {
  font-family: $font--heading-display;
  text-transform: uppercase;
  font-weight: 100;
}

// h1
@mixin h1 {
  @include font-smoothing;
  @include font--heading;
  font-size: 30px;
  line-height: 28px;
  @include breakpoint($landscape-up) {
    font-size: 36px;
    line-height: 34px;
  }
}

// h2
@mixin h2 {
  @include font-smoothing;
  @include font--heading;
  font-size: 25px;
  line-height: 24px;
}

// h3 and h4
@mixin h3 {
  @include font-smoothing;
  @include font--heading;
  font-size: 18px;
  line-height: 16px; // requested by brand specifically
}

///
/// Nav
///

@mixin font--nav {
  @include font-smoothing;
  font-family: $font--subheading-alt;
  text-transform: uppercase;
  font-size: 16px;
}

@mixin button-brand-base() {
  height: 44px;
  width: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $color-black;
  color: $color-black;
  cursor: pointer;
  border-radius: 10px;
}

@mixin button--primary() {
  background: $color-sbx-red;
  border-color: $color-sbx-red;
  color: $color-white;
  &:hover {
    background: $color-white;
    border-color: $color-sbx-red;
    color: $color-sbx-red;
  }
}

@mixin button--secondary() {
  background: $color-white;
  border-color: $color-black;
  color: $color-black;
  &:hover {
    background: $color-black;
    border-color: $color-black;
    color: $color-white;
  }
}

@mixin button--secondary-light {
  background: transparent;
  border-color: $color-white;
  color: $color-white;
  &:hover {
    background: transparent;
    border-color: $color-white;
    color: $color-white;
  }
}

@mixin button-rounded {
  @include button-brand-base;
  @include NeueHaasGrotesk-bold;
  border-radius: 10px;
  transition: all 0.5s;
  font-size: 15px;
  border-width: 1px;
}

@mixin button-rounded-base {
  @include button-rounded;
  padding: 9px 10px 8px;
  width: auto;
  min-width: 150px;
}

@mixin button-large-rounded-base {
  @include button-rounded-base;
  padding: 9px 10px 8px;
  width: auto;
  min-width: 250px;
  &:hover {
    background: $color-sbx-fierce;
    border-color: $color-sbx-fierce;
    color: $color-white;
  }
}

@mixin button--rounded-light {
  background: $color-white;
  border-color: $color-black;
  color: $color-black;
  &:hover {
    background: $color-black;
    border-color: $color-black;
    color: $color-white;
  }
}

@mixin button--rounded-dark {
  background: $color-black;
  border-color: $color-white;
  color: $color-white;
  &:hover {
    background: $color-white;
    border-color: $color-black;
    color: $color-black;
  }
}

@mixin button--large-rounded-light {
  background: $color-white;
  border-color: $color-black;
  color: $color-black;
}

@mixin button--large-rounded-dark() {
  background: $color-black;
  border-color: $color-black;
  color: $color-white;
}

@mixin button--border-flicker-anima() {
  &:hover {
    color: $color-black;
    background-color: transparent;
    border: none;
    &:after {
      content: '';
      position: absolute;
      border: 1px solid $color-black;
      width: 100%;
      height: 100%;
      border-radius: 9px;
      animation-name: border-flicker-anima;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    }
  }
}

@mixin button--border-light-flicker-anima() {
  &:hover {
    color: $color-white;
    background-color: transparent;
    border: none;
    &:after {
      content: '';
      position: absolute;
      border: 1px solid $color-white;
      width: 100%;
      height: 100%;
      border-radius: 9px;
      animation-name: border-flicker-anima;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-out;
    }
  }
}

// Font Mixin : Neue Haas Grotesk Display Pro
@mixin NeueHaasGrotesk-regular {
  font-family: $NeueHaasGrotesk;
  font-weight: 400;
  font-style: normal;
}

@mixin NeueHaasGrotesk-medium {
  font-family: $NeueHaasGrotesk;
  font-weight: 500;
  font-style: normal;
}

@mixin NeueHaasGrotesk-bold {
  font-family: $NeueHaasGrotesk;
  font-weight: 700;
  font-style: normal;
}

@mixin NeueHaasGrotesk-black {
  font-family: $NeueHaasGrotesk;
  font-weight: 900;
  font-style: normal;
}

@mixin Knockout49 {
  font-family: $font-family-knockout;
  font-weight: unset;
  font-style: normal;
}

@mixin font--vcr-osd-mono {
  font-family: $font--vcr-osd-mono;
  font-style: normal;
}

// Outline style on focused elements for keyboard accessibility
@mixin outline {
  outline: 1px solid $color-focus !important;
}
///
/// Afterpay Modal Styles
///
@mixin afterpay--modal() {
  .elc-iframe-modal-mask {
    .elc-iframe-modal-wrapper {
      margin-top: 19%;
      top: 18%;
      @include breakpoint($landscape-up) {
        margin-top: 5%;
        max-height: 630px;
        top: 25%;
      }
      .icon-close {
        border: none;
        background-color: $color-white;
      }
      .elc-iframe {
        margin-top: -5%;
        padding-bottom: 20px;
        @include breakpoint($landscape-up) {
          margin-top: -3%;
          padding-bottom: unset;
        }
      }
    }
  }
}

// Product badge default styling
// scale factors are expected as numbers, default is 1
// scale factors multiply $badge-default-width
@mixin product-image-badge($mobile-scale: 1, $portrait-scale: 1, $landscape-scale: 1) {
  aspect-ratio: 1/1;
  position: absolute;
  #{$rdirection}: 25px;
  #{$ldirection}: unset;
  bottom: 10px;
  max-width: 30vw;
  width: calc(#{$badge-default-width} * #{$mobile-scale});
  @include breakpoint($portrait-up) {
    width: calc(#{$badge-default-width} * #{$portrait-scale});
  }
  @include breakpoint($landscape-up) {
    width: calc(#{$badge-default-width} * #{$landscape-scale});
  }
}
