@import '../theme-bootstrap';

.elc-responsive-modal-mask {
  .elc-product {
    .notify-me-confirmation-content {
      @include breakpoint($landscape-up) {
        padding: 100px 0;
      }
    }
    &-notify-me-content,
    &-notify-me-confirmation-content {
      margin-top: 50px;
      padding: 10px 30px 60px;
      @include breakpoint($landscape-up) {
        margin-top: 40px;
        padding: 0 40px 30px;
      }
    }
    &-notify-me-pop-up-wrapper,
    &-notify-me-confirmation-wrapper {
      &.elc-responsive-modal {
        transform: none;
        min-height: 100%;
        top: 0;
        @include breakpoint($landscape-up) {
          top: 28%;
          margin-#{$ldirection}: -275px;
          height: auto;
          width: 550px;
          min-height: 432px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .elc-product-notify-me-pop-up-close-button {
          background: transparent;
        }
      }
    }
    &-notify-me-content {
      font-family: $NeueHaasGrotesk;
      position: relative;
      height: 90%;
      width: 100%;
      display: flex;
      flex-direction: column;
      .elc-floating-label-input {
        .elc-input-field,
        .elc-floating-label-string {
          font-family: inherit;
          letter-spacing: 1px;
        }
      }
      .elc-floating-label-input-error {
        .elc-floating-label-input-field {
          border-color: $color-red;
          color: $color-red;
        }
        .elc-floating-label__label-wrapper {
          span {
            color: $color-red;
          }
        }
      }
      .elc-input-error {
        padding: 0;
        margin: 0;
        min-height: auto;
        p {
          padding: 5px 0;
          margin: 0;
          font-family: $NeueHaasGrotesk;
          font-size: 12px;
          color: $color-red;
        }
      }
    }
    &-notify-me-notice {
      align-items: center;
      .elc-checkbox-icon {
        margin-#{$ldirection}: -3px;
      }
      .elc-checkbox-check-area {
        top: 3px;
      }
    }
    &-notify-me-title-label {
      font-family: $font--subheading-alt;
      font-size: 28px;
      letter-spacing: 0;
      line-height: 1;
    }
    &-notify-me-confirmation-content {
      max-width: none;
      text-align: #{$ldirection};
    }
    &-notify-me-description-label,
    &-notify-me-gdpr-notice-label,
    &-notify-me-confirmation-title,
    &-notify-me-confirmation-content-text,
    &-notify-me-info-your-data {
      color: $color-black;
      font-family: $NeueHaasGrotesk;
      letter-spacing: 1px;
    }
    &-notify-me-description-label {
      font-family: $font--text;
      font-size: 14px;
      margin-top: 15px;
      letter-spacing: 1px;
    }
    &-notify-me-gdpr-notice-label {
      font-size: 14px;
    }
    &-notify-me-confirmation-content-text {
      font-family: $font--text;
      padding: 10px 0 0;
    }
    &-notify-me-confirmation-title {
      @include swap_direction(margin, 15px 15px 15px 0);
      font-family: $font--subheading-alt;
      font-size: 30px;
    }
    &-notify-me-notice-wrapper {
      padding-top: 15px;
    }
    &-notify-me-info {
      position: relative;
      width: 90%;
      align-items: center;
      order: 1;
      flex-flow: wrap;
      &-icon {
        width: 22px;
        height: 22px;
      }
      &-button {
        background: transparent;
        padding: 5px;
        height: auto;
        width: fit-content;
        @include breakpoint($landscape-up) {
          min-width: 15px;
        }
        &:hover {
          background: transparent;
        }
        .elc-product-notify-me-info-icon {
          display: inline-block;
        }
        &:active,
        &:focus {
          .elc-product-notify-me-info-icon {
            border: 1px solid $color-border;
          }
        }
      }
    }
    &-gdpr-popup {
      position: relative;
      bottom: auto;
      display: none;
      background-color: $color-transparent--dark-gray;
      border: 1px solid $color-border;
      padding: 3px 14px;
      #{$ldirection}: 25px;
      &.active {
        display: block;
      }
      &-information {
        font-family: $NeueHaasGrotesk;
        letter-spacing: 1px;
        color: $color-white;
        font-size: 14px;
        a {
          color: inherit;
        }
      }
      p {
        margin: 0;
      }
    }
    &-notify-me-gdpr-read-confirmation {
      color: $color-red;
      font-size: 12px;
    }
    &-notify-me-info-your-data {
      line-height: 1;
      font-size: 12px;
    }
    &-notify-me-confirmation-icon {
      display: none;
    }
    &-notify-me-form-wrapper {
      margin: 20px 0 0;
      .elc-input-field {
        height: 56px;
        padding: 10px 15px;
        @include breakpoint($landscape-up) {
          height: 64px;
        }
      }
    }
    &-notify-me-submit-button,
    &-notify-me-confirmation-continue-button {
      &.elc-button {
        @include button;
        width: 100%;
        height: 56px;
        line-height: 1;
        text-transform: none;
        margin-top: 15px;
        margin-bottom: 15px;
        border-radius: 10px;
        @include breakpoint($landscape-up) {
          height: 64px;
        }
        &.elc-product-notify-me-submit-button-disabled {
          background-color: $color-light-med-gray;
          border: $color-light-med-gray;
        }
      }
    }
    &-notify-me-submit-button {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      &-disabled {
        width: 100%;
        background: $color-border;
        pointer-events: none;
        border-color: $color-border;
        color: $color-white;
        cursor: not-allowed;
        &:hover,
        &:active,
        &:visited {
          background-color: $light-gray;
          border-color: $light-gray;
          color: $color-white;
        }
      }
    }
    &-notify-me-confirmation-continue-button {
      position: relative;
      margin: 25px 0;
    }
    &-notify-me-confirmation-close-button,
    &-notify-me-pop-up-close-button {
      margin: 0;
      padding: 20px;
      width: auto;
      z-index: 1;
      @include breakpoint($landscape-up) {
        position: absolute;
        top: 0;
        #{$rdirection}: 0;
      }
    }
    &-notify-me-confirmation-close-button {
      background: transparent;
    }
  }
}
